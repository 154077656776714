<template>
  <section class="occupancyCities">
    <div class="occupancyCities__contentDescription">
      <p class="occupancyCities__description">Total de horas por todas las ciudades</p>
      <div class="occupancyCities__loadingText skeletonS" v-if="isLoading"></div>
      <h1 v-else>{{ `${totalHours} de ${hoursInAllCities} horas posibles` }}</h1>
    </div>
    <div class="occupancyCities__contentData">
      <div class="occupancyCities__loadingPie skeletonS" v-if="isLoading"></div>
      <pie-chart :donut="true" :data="dataToPie" suffix="%" height="400px" :library="chartOptions" v-else-if="dataToPie.length"></pie-chart>
      <span v-else>No se han registrado horas de conexion en este filtro</span>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      width: 0,
      height: 0,
      chartOptions: {
        responsive: true,
        colors: ["#165BAA", "#A155B9", "#F765A3", "#FFCD56", "#4BC0C0", "#FF6B00", "#CFD1D4"],
      },
    };
  },
  computed: {
    ...mapGetters("Occupation", ["usersInRooms", "hoursPerCity", "loadingIn"]),
    dataToPie() {
      const totalHours = this.hoursInAllCities;
      const emptyHours = totalHours - this.totalHours;
      const dataCities = this.hoursPerCity
        .filter(({ hours }) => hours)
        .map(({ cityName, hours }) => [cityName, ((hours / (totalHours || this.totalHours)) * 100).toFixed(1)]);

      if (totalHours) {
        const idx = dataCities.length;
        this.chartOptions.colors[idx] = "#49505730";
        dataCities.push(["Vacio", ((emptyHours / totalHours) * 100).toFixed(1)]);
      }
      return dataCities;
    },
    hoursInAllCities() {
      const hours = (this.usersInRooms?.total || 0) / 60;
      return hours ? Math.round(hours) : 0;
    },
    totalHours() {
      const totalHours = this.hoursPerCity.reduce((acc, item) => (acc += item.hours), 0);
      return Math.round(totalHours);
    },
    isLoading() {
      return this.loadingIn("hoursPerCity");
    },
  },
};
</script>

<style lang="scss">
.occupancyCities {
  width: 100%;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px #00000040;
  &__contentDescription {
    padding: 20px;
    border-bottom: 2px solid #ececec;
  }
  &__description {
    font-size: 17px;
    font-weight: 600;
  }
  &__contentData {
    @include Flex(row);
    width: 100%;
    height: calc(100% - 103px);
    gap: 10px;
    padding: 15px 20px 20px 20px;
  }
  &__loadingText {
    height: 37px;
    width: 175px;
    border-radius: 12px;
  }
  &__loadingPie {
    height: 400px;
    width: 100%;
    max-width: 100%;
    border-radius: 12px;
  }
}
</style>
